<template>
    <div class="myClass">
        <el-tabs v-model="active" @tab-click="handleClick" >
            <el-tab-pane label="全部"></el-tab-pane>
            <el-tab-pane label="付费课程"></el-tab-pane>
            <el-tab-pane label="免费课程"></el-tab-pane>
            <el-tab-pane label="已过期"></el-tab-pane>
        </el-tabs>

        <div v-if="dataList.length">
            <div v-for="(item,index) in dataList" :key="index">
                <div class="myClass_box">
                    <div class="myClass_box_left">
                        <div class="myClass_box_left_img">
                            <img :src="item.coverPath" alt />
                        </div>
                        <div class="myClass_box_left_content">
                            <p>{{ item.packageName }}</p>
                            <p>
                                <span>到期时间：</span>
                                <span>{{ item.effectiveEtime }}</span>
                            </p>
                            <p>
                                <el-progress
                                    :percentage="item.studyCourseCount>0? Number(item.studyCourseCount) / Number(item.classHourCount1) * 100:0"
                                    :show-text="false"
                                    :stroke-width="10"
                                ></el-progress>
                                <span>已学习：{{ item.studyCourseCount }}/{{ item.classHourCount1 }}课时</span>
                            </p>
                        </div>
                    </div>
                    <el-button  v-if="item.operationFlag == 1" style="cursor:pointer;" type="success" @click="goToOtherSystem()" size="medium" round>去实战</el-button>
                    <el-button v-if="item.operationFlag == 1" @click="handleChange_abc(index, item.id)" style="cursor:pointer;" type="primary" size="medium" round>去听课</el-button>
                    <button class="myClass_box_right" v-if="item.operationFlag == 0" @click="handleChange_abc(index, item.id)" style="cursor:pointer;">开始学习</button>
                </div>
                <el-collapse @change="handleChange(index, item.id)" v-model="activeNames" accordion>
                    <el-collapse-item :name="index">
                        <template slot="title">
                            <!-- <div class="coll-bd"></div> -->
                        </template>
                        <div class="myClass_box_list">
                            <div class="myClass_box_list_tips">
                                <span  style="background:#ffffff;border:none;padding:0px;margin:0px;font-size:16px;font-weight:500">【科目】:</span>
                                <span  :class="item.sltId == key.id ? 'active' : ''" v-for="(key,value) in item.sltObj.types" @click="onSlt(key.id, index, value)" :key="value">{{ key.name }}</span>
                                <span  style="margin-left:10px; background:#ffffff;border:none;padding:0px;margin:0px;font-size:16px;font-weight:500">【年份】:</span>
                                <span v-for="(yearItem,yearIndex) in yearTimeList" :class="yearActiveIndex == yearIndex ? 'active' : ''"  :key="yearIndex+'1'" @click="yearTimeClick(yearItem,yearIndex,index)">{{yearItem.yearName}}</span>
                            </div>
                            <!-- <div class="myClass_box_wire"></div> -->
                            <div class="myClass_box_list_lessons">
                                <div
                                    class="myClass_box_list_lessons_item"
                                    v-for="(item2,index2) in item.sltObj.courseList"
                                    :key="index2"
                                >
                                    <div class="myClass_box_list_lessons_item_left">
                                        <div class="myClass_box_list_lessons_item_left_top">
                                            <img src="../../assets/mySelf/my-live.png" alt v-if="item2.type == 1" />
                                            <img src="../../assets/mySelf/my-video.png" alt v-else />
                                            <p>{{ item2.name }} <span v-if="item2.isLastStudy ==1">(上次学习)</span></p>
                                        </div>
                                        <div class="myClass_box_list_lessons_item_left_bottom">
                                            <el-progress
                                                :percentage="item2.studyCourseCount>0? Number(item2.studyCourseCount) / Number(item2.courseCount ) * 100:0"
                                                :show-text="false"
                                                :stroke-width="10"
                                            ></el-progress>
                                            <span>已学习：{{ item2.studyCourseCount }}/{{ item2.courseCount }}课时</span>
                                        </div>
                                    </div>
                                    <button style="cursor:pointer;" v-if="item2.type == 1" class="myClass_box_list_lessons_item_right" @click="goLiveItemPage(item2)">看直播</button>
                                    <button style="cursor:pointer;" v-else class="myClass_box_list_lessons_item_right" @click="goVideo(item2)">{{ item2.studyState == 1 ? '继续学习' : item2.studyState == 2 ? '重新学习' : '去学习' }}</button>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <div class="noMore" v-else>
            <div class="coursediv_empty_img">
                <img src="@/assets/class_empty.png" alt="">
            </div>
            暂无数据
        </div>
    </div>
</template>

<script>
import { has_buy, course_list,getLoginStatus } from "../../api/myself"
export default {
    name: 'myClass',
    data() {
        return {
            dataList: [],
            active: 0,// 0、全部 1、付费 2、免费 3、过期
            sltObj: {},
            activeNames: '',
            yearActiveIndex:0,
            addIdx:"",
            yearTimeList:[
               
            ],
            yearActiveVal:''
        }
    },
    mounted() {

        this.has_buy();
    },
    methods: {
        goToOtherSystem(){
            getLoginStatus().then((res)=>{
                console.log("Res",res);
                localStorage.setItem("otherSystem",res.data.data);
                let routeUrl =this.$router.resolve({
                    path: '/practice',
                   
                });
                window.open(routeUrl.href, '_blank');

            })
        },
        // 接口
        has_buy() {
            let obj = {
                type: this.active
            }
            has_buy(obj).then(res => {
                res.data.data.forEach(item => {
                    item.isShow = false;
                    item.sltId = -1;
                    item.sltObj = { courseList: [], types: [] }
                });
                this.dataList = res.data.data;
            })
        },
        //二级
        course_list(index, id, idx) {
            this.addIdx = idx;
            let obj = { coursePackageId: id  }
            if(idx != -1){
                obj.secondTypeId = idx
            }
            if(this.yearActiveVal){
                if(this.yearActiveVal.yearName == '全部'){
                    obj.yearTime = "";
                }else{
                    obj.yearTime = this.yearActiveVal.yearName;

                }
            }
            course_list(obj).then(res => {
                var course_list = res.data.data;
                for(var i = 0;i<course_list.courseList.length;i++){
                    course_list.courseList[i].coursePackageId = id;
                }
                this.dataList[index].sltObj = course_list;
            })
        },
        onSlt(id, index, idx) {

            this.dataList[index].sltId = id;
            this.course_list(index, this.dataList[index].id, id);
        },
        yearTimeClick(val,yearIndex,index){
            this.yearActiveIndex = yearIndex;
            this.yearActiveVal = val;
            if(this.addIdx != ''){
                this.course_list(index, this.dataList[index].id, this.addIdx);
            }else{
                this.course_list(index, this.dataList[index].id);
            }
            


        },
        handleClick() {
            this.has_buy();
        },
        //二级
        course_listAndYearData(index, id, idx) {
            this.addIdx = idx;
            let obj = { coursePackageId: id  }
            if(idx != -1){
                obj.secondTypeId = idx
            }
            this.yearTimeList = [
                {
                    yearName:'全部'
                }
            ]
           // console.log("1",this.yearTimeList);
            if(this.yearActiveVal){
                if(this.yearActiveVal.yearName == '全部'){
                    obj.yearTime = "";
                }else{
                    obj.yearTime = this.yearActiveVal.yearName;

                }
            }
            course_list(obj).then(res => {
                var course_list = res.data.data;
                const arr = new Array();
                for(var i = 0;i<course_list.courseList.length;i++){
                    //console.log("course_list.courseList[i].yearTime",course_list.courseList[i].yearTime);
                    // let str = {
                    //     yearName:JSON.stringify(course_list.courseList[i].yearTime)
                    // };

                    // const containsObj = this.yearTimeList.some(item => item.yearName == str.yearName);
                    // console.log("containsObj",containsObj);
                    // if(!containsObj){
                    //     if(course_list.courseList[i].yearTime){
                    //         this.yearTimeList.push({yearName:JSON.stringify(course_list.courseList[i].yearTime)});
                    //     }
                        
                    // }
                    if(course_list.courseList[i].yearTime != null){
                        arr.push({yearName:JSON.stringify(course_list.courseList[i].yearTime)});
                    }

                    
                    //this.yearTimeList.push({yearName:JSON.stringify(course_list.courseList[i].yearTime)});
                    
                    course_list.courseList[i].coursePackageId = id;
                }
               
                const uniqueArr = this.uniqueBy(arr, 'yearName');
                //console.log("2",uniqueArr);
                this.yearTimeList = this.yearTimeList.concat(uniqueArr);
                //console.log("3",this.yearTimeList);
                this.dataList[index].sltObj = course_list;
            })
        },
        uniqueBy(array, key) {
            const seen = new Set();
            return array.filter((item) => {
                const keyValue = item[key];
                return seen.has(keyValue) ? false : seen.add(keyValue);
            });
        },
        handleChange(index, id) {
            this.yearActiveIndex = 0;
            this.yearActiveVal = this.yearTimeList[0];
            let dataList = this.dataList;
            dataList[index].isShow = !dataList[index].isShow;
            if (dataList[index].isShow) {
                this.course_listAndYearData(index, id);
            }

        },
        handleChange_abc(index, id){
            let dataList = this.dataList;
            this.yearActiveIndex = 0;
            this.yearActiveVal = this.yearTimeList[0];
            if(!dataList[index].isShow){
                this.activeNames=index
            }else{
                this.activeNames='';
            }
            dataList[index].isShow = !dataList[index].isShow;
            if (dataList[index].isShow) {
                this.course_listAndYearData(index, id);
            }
        },
        // 去视频
        goVideo(item){
            // console.log(item);
            var videoObj={
                classHourId:item.recentlyStudyClassHourId,
                videoId:item.recentlyStudyClassHourVideoId,
                classHourName:item.name,
            }
            var clickItemObj={
                courseId:item.id,
            }
            var classDetailObj={
                isBuy:1,
                courseName:'获取值'
            }
            //classDetail.isBuy
            let routeUrl =this.$router.resolve({
                path:'/videoRoom',
                query:{
                  courseId: item.id,
                  coursePackageId:item.coursePackageId,
                  classHourId:item.recentlyStudyClassHourId,
                  // videoObj:JSON.stringify(videoObj),
                  // clickItem:JSON.stringify(clickItemObj),
                  // classDetail:JSON.stringify(classDetailObj),
                  studyTimeLength:item.recentlyStudyClassHourVideoWatchLength,
                  // type:0
                }
            })
            localStorage.setItem("videoRoom",JSON.stringify({
              videoObj: videoObj,
              clickItem: clickItemObj,
              classDetail: classDetailObj,
              coursePackageId:item.coursePackageId,
              studyTimeLength:item.recentlyStudyClassHourVideoWatchLength,
              type:0
            }))
            window.open(routeUrl.href, '_blank');
        },
        goLiveItemPage(item){
            // console.log(item);
            this.$router.push({
                path:'/myself/liveItem',
                query:{
                    item:JSON.stringify(item),
                }
            })
        }

    }
}
</script>

<style>
.titleBoxMyclass{

}
.noMore {
    text-align: center;
    width: 100%;
    color: #999;
    font-size: 14px;
    margin: 40px 0;
}
</style>